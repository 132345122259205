import React from "react"
import Layout from "../../components/layout"
import Seo from "../../components/seo"
import ProjectsNavigation from "../../components/projects-navigation"
import ProjectLife from "../../components/project-life"
import { content } from "../pages.module.scss"

export default function ProjektLife() {
  const currentLang = "pl"
  return (
    <Layout lang={currentLang}>
      <Seo
        lang={currentLang}
        title="Projekt - Logo"
        keywords={[`powszuk`, `design`]}
      />
      <div className={content}>
        <ProjectLife lang={currentLang} />
      </div>
      <ProjectsNavigation lang={currentLang} project="life" />
    </Layout>
  )
}
